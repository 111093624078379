// Principal libraries
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { fonts } from '../../../utils/constants';

const Why = () => (
  <Container id="why">
    <Content>
      <Column>
        <Box>
          <Title>¿Por qué utilizar Estuap?</Title>
          <Text>
            Estuap dispone de un aula virtual para que docentes y educadores puedan
            dar sus respectivas clases online y en vivo (streaming) a través de la
            web, y los alumnos sean capaces de asistir a las clases desde sus hogares
            utilizando cualquier dispositivo conectado a la red, pudiendo interactuar
            con el docente. El alumno no necesitará crear una cuenta, y de esta forma
            permanece en anonimato su identidad, para la tranquilidad de los padres o
            tutores. Accede a través de un enlace proporcionado por el profesor, y
            puede escribir en el chat del streaming.
          </Text>
          <Button to="/register">Comienza ahora</Button>
        </Box>
      </Column>
      <Column>
        <Image src="/assets/images/presentation.png" />
      </Column>
    </Content>
  </Container>
);

const Container = styled.div`
  width: 100%;
  padding: 80px 20px;
  box-sizing: border-box;
  text-align: center;
  background-image: url('/assets/images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 666px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  width: 80%;
  text-align: left;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 666px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  color: #021944;
  font-family: ${fonts.principal};
  font-weight: bold;
  width: 100%;
  letter-spacing: 0.5px;
  font-size: 40px;
  margin: 20px auto;
`;

const Button = styled(Link)`
  box-sizing: border-box;
  padding: 20px 50px;
  background: #021944;
  border-radius: 10px;
  color: white;
  outline: none;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border: 2px solid #021944;
  transition: 1s all;
  :hover {
    color: #021944;
    background: white;
  }
`;

const Text = styled.p`
  font-family: ${fonts.principal};
  margin: 0;
  font-size: 1em;
  letter-spacing: 1px;
  color: black;
  text-align: left;
  margin-bottom: 100px;
`;

const shakeAnimation = keyframes`
   10%, 90% {
    transform: translate3d(0, -3px, 0);
  }
  
  20%, 80% {
    transform: translate3d(0, 4px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -6px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 6px, 0);
  }
`;

const Image = styled.img`
  width: 100%;
  animation: ${shakeAnimation} 10s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
`;

export default Why;
