// REFACTOR/TODO
// Principal libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as Route, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { MdMail } from 'react-icons/md';

// Extras
import { actions, selectors } from '../../../reducers/account';
import accountActions from '../../../actions/account';
import { devicesMax } from '../../../utils/constants';
import { fonts } from '../../../utils/constants';

const width = window ? window.innerWidth : 650;

const Footer = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(selectors.isLogged);

  const handleSession = () => {
    dispatch(actions.showLogin(true));
  };

  const handleLogout = () => {
    dispatch(accountActions.logout());
  };

  return (
    <Container id="footer">
      <Wrapper>
        <Content>
          <Title>Acerca de</Title>
          <List>
            <Option>
              <Link href="#">Estuap</Link>
            </Option>
          </List>
        </Content>
        <Content>
          <Title>Ayuda</Title>
          <List>
            <Option>
              <Link href="/#tutorials">Tutoriales</Link>
            </Option>
            <Option>
              <Link href="#">Clases online</Link>
            </Option>
          </List>
        </Content>
        {width > 600 && (
          <Content>
            <Title>Redes sociales</Title>
            <List>
              <Option>
                <Link
                  href="https://www.facebook.com/estuapofficial/"
                  target="_blank"
                >
                  Facebook
                </Link>
              </Option>
              <Option>
                <Link
                  href="https://www.instagram.com/estuapofficial/"
                  target="_blank"
                >
                  Instagram
                </Link>
              </Option>
              <Option>
                <Link
                  href="https://www.youtube.com/channel/UCE544OmkM3lpmpdGnwrpO4w"
                  target="_blank"
                >
                  Youtube
                </Link>
              </Option>
            </List>
          </Content>
        )}
        <Content>
          <Title>Mi cuenta</Title>
          <List>
            {!isLogged ? (
              <>
                <Option>
                  <Link onClick={handleSession}>Iniciar sesión</Link>
                </Option>
                <Option>
                  <RouteTo to="/register">Registrar como profesor</RouteTo>
                </Option>
              </>
            ) : (
              <>
                <Option>
                  <NavLinkTo onClick={handleLogout} to="/">
                    Cerrar sesion
                  </NavLinkTo>
                </Option>
                {/* <Option>
                                            <RouteTo to="/account">Ajustes</RouteTo>
                                        </Option>
                                        <Option>
                                            <RouteTo to="/profile">Perfil</RouteTo>
                                        </Option> */}
              </>
            )}
          </List>
        </Content>
        <Content>
          <Title>Contacto</Title>
          <List>
            <Option>
              <Img src="/assets/images/uy.png" />
              <Text>093 792 629</Text>
            </Option>
            <Option>
              <Img src="/assets/images/ar.png" />
              <Text>(011) 26176787</Text>
            </Option>
            <Option>
              <Link href="mailto:soporte@estuap.com" target="_blank">
                <MdMail size={30} color="white" />
                <Text>soporte@estuap.com</Text>
              </Link>
            </Option>
          </List>
        </Content>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  background-color: #0e67be;
  padding: 10px 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  @media ${devicesMax.tablet} {
    flex-wrap: wrap;
  }
`;

const Content = styled.div`
  height: 170px;
  @media (max-width: 860px) {
    width: 50%;
    text-align: center;
    margin: 0 auto;
  }
`;

const Title = styled.p`
  margin: 0;
  font-weight: bold;
`;

const List = styled.ul`
  padding: 0 5px;
`;

const Option = styled.li`
  list-style: none;
  margin: 15px 0;
  @media (max-width: 900px) {
    white-space: nowrap;
  }
`;

const Link = styled.a`
  cursor: pointer;
  font-family: ${fonts.principal};
  color: whitesmoke;
`;

const RouteTo = styled(Route)`
  cursor: pointer;
  font-family: ${fonts.principal};
  color: whitesmoke;
`;

const NavLinkTo = styled(NavLink)`
  cursor: pointer;
  font-family: ${fonts.principal};
  color: whitesmoke;
`;

const Button = styled.button`
  color: #0e67be;
  background-color: white;
  font-family: ${fonts.principal};
  width: 150px;
  padding: 20px;
  margin-top: 5px;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Img = styled.img`
  height: 30px;
  width: 30px;
`;

const Text = styled.p`
  margin: 0 5px;
  display: inline-block;
  height: 30px;
  vertical-align: text-bottom;
  @media (max-width: 450px) {
    font-size: 0.7em;
  }
`;

export default Footer;
