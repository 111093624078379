import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const Tutorial = () => {
  const registerRef = useRef();
  const loginRef = useRef();
  const [blur, setBlur] = useState({ register: true, login: true });

  const handleRegister = () => {
    setBlur({ ...blur, register: false });
  };

  const handleLogin = () => {
    setBlur({ ...blur, login: false });
  };

  return (
    <Container id='tutorials'>
      <Content>
        <Box>
          <Title>Tutorial para registrarte en la plataforma como profesor</Title>
          <Video
            onPlaying={handleRegister}
            blur={blur.register}
            controls
            src="/assets/videos/register.mov"
            type="video/mov"
          />
        </Box>
        <Box>
          <Title>Tutorial para iniciar una trasmisión en vivo</Title>
          <Video
            onPlaying={handleLogin}
            blur={blur.login}
            controls
            src="/assets/videos/login.mov"
            type="video/mov"
          />
        </Box>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  background-image:url('/assets/images/background-all.jpg');
  padding: 90px 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: white;
  font-family: 'Muli';
  width: 90%;
  letter-spacing: 0.5px;
  font-size: 20px;
  margin: 30px auto;
  text-align: center;
`;

const Box = styled.div`
  text-align: center;
  width: 100%;
`;

const Video = styled.video`
  width: 90%;
  outline: none;
  margin: 5px;
  filter: ${({ blur }) => (blur ? 'blur(4px)' : 'blur(0px)')};
  object-fit: cover;
`;

export default Tutorial;
