// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { GoPerson } from 'react-icons/go';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { AiOutlineBarChart } from 'react-icons/ai';


const Section = () => (
    <Container>
        <Icon color="#62aaef" size={100} />
        <Title>Día a día hay más personas trabajando en Estuap</Title>
        <Subtitle>No se necesita tener una empresa, solo un nombre, conocimiento y ganas de conseguir dinero. Por ello, aumenta el número de profesores en nuestra plataforma, y aún más ¡ los alumnos !</Subtitle>
        <Content>
            <Column>
                <Text>+5000 profesores</Text>
                <Bubble>
                    <FaChalkboardTeacher size={52} color="white" />
                </Bubble>
            </Column>
            <Column>
                <Text>+20.000 alumnos</Text>
                <Bubble>
                    <GoPerson size={52} color="white" />
                </Bubble>
            </Column>
        </Content>
    </Container>
);

const Container = styled.div`
    width:100%;
    background-color: #f4fbff;
    padding:80px 20px;
    box-sizing:border-box;
    text-align:center;
`;

const Content = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;

const Column = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:50%;
`;

const Title = styled.h2`
    color:#021944;
    font-family: "Muli";
    width:90%;
    letter-spacing: 0.5px;
    font-size: 32px;
    margin:20px auto;
`;
const Subtitle = styled.h3`
    color:#161616;
    font-family:'Raleway';
    font-weight: 100;
    font-size:1em;
    width:90%;
    margin:40px auto;
    font-size: 20px;
`;

const Text = styled.h2`
    font-family:"Muli";
    color: #7c91ef;
`;

const Icon = styled(AiOutlineBarChart)`
    transform:rotate(-20deg);
`;

const Bubble = styled.div`
    background-color:#7c91ef;
    border-radius:100px;
    padding:10px;
    transform:rotate(-10deg);
`;


export default Section;