// Principal libraries
import React from 'react';

// Components
import Header from './header/Header';

// Extras
import Section from './section/Information';
import Tutorial from './tutorial/Tutorial';
import Why from './section/Why';

// Assets

const Home = () => {
  return (
    <>
      <Header />
      <Tutorial />
      <Why />
      <Section />
    </>
  );
};

export default Home;
