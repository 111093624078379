// Principal libraries
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as LinkTo } from 'react-router-dom';
import TextLoop from 'react-text-loop';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Extras
import { actions, selectors } from '../../../reducers/account';
import { fonts, colors } from '../../../utils/constants';

const Header = () => {
  const history = useHistory();
  const isLogged = useSelector(selectors.isLogged);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  const handleKey = (e) => {
    if (value.length && e.key === 'Enter') {
      history.push(`/online/${value}`);
    }
  };

  const handleLogin = () => {
    dispatch(actions.showLogin(true));
  };

  return (
    <Container>
      <Content>
        <Image src="assets/images/logo.png" />
        <RowText>
          <Text>Prueba crear </Text>
          <TextLoop interval={4000}>
            <Text mark yellow>
              clases online
            </Text>
            <Text mark yellow>
              streaming
            </Text>
          </TextLoop>
          <Text> ¡es super sencillo de utilizar! </Text>
        </RowText>
        <RowText>
          <Text small>Servicio gratuito hasta finalizar la cuarentena. </Text>
          <TextLoop interval={4000}>
            <Text mark yellow small>
              ¡Aprovecha!
            </Text>
            <Text mark yellow small>
              ¡Disfruta!
            </Text>
          </TextLoop>
        </RowText>
        <Input
          placeholder="Ingresa aquí el código de su profesor para entrar a la clase"
          value={value}
          onChange={({ target }) => {
            setValue(target.value);
          }}
          onKeyDown={handleKey}
        />
        <Row>
          {!isLogged && (
            <>
              <Button to="/register">Registrate como profesor</Button>
              <Button delay onClick={handleLogin}>
                Iniciar sesión
              </Button>
            </>
          )}
        </Row>
      </Content>
      <Line>
        <Option>
          <NavLink href="/#why">Información sobre clases en vivo</NavLink>
        </Option>
        <Option>
          <NavLink href="/#tutorials">¿Cómo empezar?</NavLink>
        </Option>
        <Option>
          <NavLink href="/#footer">Contáctenos</NavLink>
        </Option>
      </Line>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 95vh;
  background-color: #0e67be;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const RowText = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;
const Line = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
`;

const Option = styled.li`
  display: inline-block;
  color: gray;
  margin: 5px;
`;

const Link = styled(LinkTo)`
  color: lightgray;
  transition: all 0.5s;
  :hover {
    color: white;
  }
`;

const NavLink = styled.a`
  color: lightgray;
  transition: all 0.5s;
  :hover {
    color: white;
  }
`;

const Image = styled.img`
  width: 20%;
`;

const Text = styled.span`
  font-family: ${fonts.principal};
  font-size: ${(props) => (props.small ? '1em' : '1.5em')};
  font-weight: ${(props) => (props.mark ? 'bold' : 'normal')};
  color: ${(props) => (props.yellow ? '#FFDD03' : 'white')};
  text-align: left;
  @media (max-width: 666px) {
    font-size: 1em;
  }
`;

const Button = styled(Link)`
  display: block;
  background-color: #1692ff;
  width: fit-content;
  padding: 10px;
  width: 320px;
  font-family: ${fonts.principal};
  text-align: center;
  font-size: 1.3em;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  margin: 50px 0;
  box-sizing: border-box;
  :hover {
    background: white;
    color: #1692ff;
  }
  @media (max-width: 840px) {
    font-size: 0.8em;
    width: 100%;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  background-color: #66b3fd;
  padding: 10px;
  color: whitesmoke;
  transition: all 1s;
  width: 95%;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 8vh;
  font-size: 1.3em;
  text-align: center;
  @media (max-width: 660px) {
    font-size: 0.8em;
  }
  :focus {
    background-color: ${colors.white};
    color: black;
    ::placeholder {
      color: ${colors.darkgray};
    }
  }
  ::placeholder {
    color: ${colors.white};
  }
`;

export default Header;
